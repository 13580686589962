/* eslint-disable react/no-array-index-key */
import React from 'react'
import styled from 'styled-components'

import ParseContent from 'components/shared/ParseContent'

const Section = styled.section`
  @media (min-width: 1200px) {
    & iframe {
      min-height: 367px;
    }
  }

  @media (max-width: 1199.98px) {
    & iframe {
      height: 260px;
    }
  }
`

const Content = styled(ParseContent)`
  & h1,
  & h2,
  & h3,
  & h4,
  & h5,
  & h6 {
    @media (min-width: 992px) {
      font-size: 26px;
    }
    & strong {
      font-weight: ${({ theme }) => theme.font.weight.extraBold};
    }
  }
`

const VideoTitle = styled.div`
  top: 0;
  left: 0;
  right: 0;
  background: black;
  padding: 0.25rem 1rem;

  & span {
    color: white;

    @media (min-width: 992px) {
      font-size: 25px;
    }
  }
`

interface VideoProps {
  // eslint-disable-next-line
  fields: GatsbyTypes.WpPage_Flexcontent_Flex_Video
}

const Video: React.FC<VideoProps> = ({ fields }) => (
  <Section className="mb-5 pb-lg-5">
    <div className="container">
      <Content content={fields.description} className="mb-5" />
      {fields.videos.length < 2 && (
        <iframe
          title="Kokall"
          src={fields.videos[0].url}
          width="100%"
          height={540}
          style={{ border: 0 }}
          allowFullScreen
          loading="lazy"
        />
      )}
      {fields.videos?.length > 1 && (
        <div className="row justify-content-center">
          {fields.videos?.map((video, index) => (
            <div className="col-md-6" key={index}>
              <div className="position-relative">
                {video?.title && (
                  <VideoTitle className="position-absolute">
                    <span>{video?.title}</span>
                  </VideoTitle>
                )}

                <iframe
                  title="Kokall"
                  src={video?.url}
                  width="100%"
                  height="100%"
                  style={{ border: 0 }}
                  allowFullScreen
                  loading="lazy"
                />
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  </Section>
)

export default Video
